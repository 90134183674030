import {
  PROPERTY_FILTER_RESET,
  PROPERTY_FILTER_SET,
  REGISTRATION_SUCCESS_POPUP_TOGGLE,
  PROPERTY_FILTER_COUNTS,
  APP_DOWNLOAD_POPUP_TOGGLE,
} from "../constants";

export const RegistrationSuccessPopupToggleAction = (payload) => {
  return {
    type: REGISTRATION_SUCCESS_POPUP_TOGGLE,
    data: payload,
  };
};

export const AppDownloadPopupToggleAction = (payload) => {
  return {
    type: APP_DOWNLOAD_POPUP_TOGGLE,
    data: payload,
  };
};

export const setPropertyFilter = (payload) => {

  return {
    type: PROPERTY_FILTER_SET,
    data: payload,
  };
};

export const restePropertyFilter = () => {
  return {
    type: PROPERTY_FILTER_RESET,
  };
};

export const setPropertyFilterCounts = (payload) => {
  return {
    type: PROPERTY_FILTER_COUNTS,
    data: payload,
  };
};
