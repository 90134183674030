import axios from "../config/NodeAxios";
import { handleApiError } from "../components/common/core/CommonFunctions";

export class CityService {
  constructor() {
    this.endpoints = {
      DROPDOWN_PUBLIC: "public/property-management/city/dropdown",
      DROPDOWNBYID_PUBLIC: "public/property-management/city/dropdown/:id",
    };
    this.config = {};
    this.config.headers = {
      "content-type": "application/json",
    };
  }

  async getCityDropdown() {
    try {
      const response = await axios.get(this.endpoints.DROPDOWN_PUBLIC);
      return response;
    } catch (error) {
      console.log("error", error);
      handleApiError(error);
    }
  }

  async getCityDropdownByID(id) {
    try {
      const response = await axios.get(
        this.endpoints.DROPDOWNBYID_PUBLIC.replace(":id", id)
      );
      return response;
    } catch (error) {
      console.log("error", error);
      handleApiError(error);
    }
  }
}
