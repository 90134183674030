import React from "react";
import { NavLink } from "react-router-dom";

export default function SocialMediaIcons({ variant }) {
  return (
    <>
      <ul className={`social-icons social-icons-${variant}`}>
        <li>
          <NavLink to="#">
            <i className="fa-brands fa-facebook-f"></i>
          </NavLink>
        </li>
        <li>
          <NavLink to="#">
            <i className="fa-brands fa-x-twitter"></i>
          </NavLink>
        </li>
        <li>
          <NavLink to="#">
            <i className="fa-brands fa-linkedin-in"></i>
          </NavLink>
        </li>
        <li>
          <NavLink to="#">
            <i className="fa-brands fa-instagram"></i>
          </NavLink>
        </li>
        <li>
          <NavLink to="#">
            <i className="fa-brands fa-youtube"></i>
          </NavLink>
        </li>
      </ul>
    </>
  );
}
