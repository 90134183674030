import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import DefaultView from "../components/views/DefaultView";
import AboutUs from "../pages/AboutUs";
import Career from "../pages/Career";
import ContactUs from "../pages/ContactUs";
import Media from "../pages/Media";
import FAQ from "../pages/FAQ";
import TermsAndConditions from "../pages/TermsAndConditions";
import Register from "../pages/Register";
import AgentRegister from "../pages/forms/AgentRegister";
import AgencyRegister from "../pages/forms/AgencyRegister";
import PropertyList from "../pages/PropertyList";
import { PageService } from "../services/PageService";
import PageBlock from "../pages/dynamic/PageBlock";
import PropertySinglePage from "../pages/PropertySinglePage";

export default function RoutesRoot() {
  const [websitePages, setWebsitePages] = useState([]);

  useEffect(() => {
    const query = "?status=true";

    new PageService()
      .getPageList(query)
      .then((response) => {
        const result = response.data.result;
        if (result) {
          setWebsitePages(result);
        }
      })
      .catch((error) => {
        console.error("Get websites paes error");
      });
  }, []);

  return (
    <Routes>
      {websitePages && (
        <>
          {websitePages.map((value, index) => (
            <Route
              key={`page_router${index}`}
              path={`/${value.page_slug}`}
              element={<PageBlock value={value} />}
            />
          ))}
        </>
      )}
      <Route
        path="/"
        element={
          <DefaultView
            head={{
              title: "PropTy - Your All-In-One AR Property Search App",
            }}
            cmp={Home}
          />
        }
      />
      <Route
        path="/about-us"
        element={
          <DefaultView
            head={{
              title: "About Us - PropTy",
            }}
            cmp={AboutUs}
          />
        }
      />
      {/* <Route
        path="/careers"
        element={
          <DefaultView
            head={{
              title: "Careers - PropTy",
            }}
            cmp={Career}
          />
        }
      /> */}
      <Route
        path="/contact-us"
        element={
          <DefaultView
            head={{
              title: "Contact Us - PropTy",
            }}
            cmp={ContactUs}
          />
        }
      />
      <Route
        path="/media"
        element={
          <DefaultView
            head={{
              title: "Media - PropTy",
            }}
            cmp={Media}
          />
        }
      />
      {/* <Route
        path="/terms-and-conditions"
        element={
          <DefaultView
            head={{
              title: "Terms & Conditions - PropTy",
            }}
            cmp={TermsAndConditions}
          />
        }
      /> */}
      {/* <Route
        path="/faq"
        element={
          <DefaultView
            head={{
              title: "Faq - PropTy",
            }}
            cmp={FAQ}
          />
        }
      /> */}
      {/* <Route
        path="/list-your-property"
        element={
          <DefaultView
            head={{
              title: "List your property - PropTy",
            }}
            cmp={Register}
          />
        }
      /> */}
      {/* <Route
        path="/list-your-property/register/agent"
        element={
          <DefaultView
            head={{
              title: "Agent Registration - PropTy",
            }}
            cmp={AgentRegister}
          />
        }
      /> */}
      {/* <Route
        path="/list-your-property/register/agency"
        element={
          <DefaultView
            head={{
              title: "Agency Registration - PropTy",
            }}
            cmp={AgencyRegister}
          />
        }
      /> */}
      <Route
        path="/properties"
        element={
          <DefaultView
            head={{
              title: "Properties - PropTy",
            }}
            cmp={PropertyList}
          />
        }
      />
      <Route
        path="/properties/:country/:city/:community/:property_info"
        element={<PropertySinglePage />}
      />
    </Routes>
  );
}
