import React from "react";
import PropertyCardLgSkeleton from "../skeleton/PropertyCardLgSkeleton";

export default function PropertyCardSkeletonLoader({ limit }) {
  return (
    <>
      <div className="row gy-4">
        {Array.from({ length: limit }).map((_, index) => (
          <PropertyCardLgSkeleton key={index} />
        ))}
      </div>
    </>
  );
}
