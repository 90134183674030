import React, { useEffect, useState } from "react";
import PropertyListHead from "./PropertyListHead";
import { restePropertyFilter, setPropertyFilter } from "../../../redux-service/action/action";
import { useDispatch, useSelector } from "react-redux";
import { Nav, Tab } from "react-bootstrap";
import PropertyFilterListMain from "./PropertyFilterListMain";

export default function PropertyListTab() {
    const [layoutToggle, setLayoutToggle] = useState(false);
    const [activeKey, setActiveKey] = useState("All");
  
    const dispatch = useDispatch();
  
    const getPropertyFilterStatus = useSelector(
      (state) => state?.propertyFilterReducer?.isFiltering
    );
  
    const getPropertyFilterCounts = useSelector(
      (state) => state?.propertyFilterReducer?.filterCounts
    );
  
    const getPropertyFilter = useSelector(
      (state) => state?.propertyFilterReducer?.filterObject
    );
  
    function setFilterHandler(eleVal) {
      if (eleVal !== "") {
        handleTabNavigation(eleVal);
        dispatch(
          setPropertyFilter({
            ...getPropertyFilter,
            completionStatus: eleVal,
          })
        );
      }
    }
  
    function resetFilterHandler() {
      dispatch(restePropertyFilter());
    }
  
    const handleTabNavigation = (tabText) => {
      setActiveKey(`${tabText}`);
    };

    useEffect(() => {
      if (!getPropertyFilterStatus) {
        handleTabNavigation("All");
      }
    }, [getPropertyFilterStatus]);
  
    useEffect(() => {
      const currentSearchParams = new URLSearchParams(window.location.search);
      const queryParams = {};
  
      for (const [key, value] of currentSearchParams.entries()) {
        queryParams[key] = value;
      }
  
      if (queryParams && Object.keys(queryParams).length > 0) {
        dispatch(setPropertyFilter(queryParams));
      }
    }, []);

    useEffect(() => {
      if(getPropertyFilter?.completionStatus){
        setActiveKey(`${getPropertyFilter?.completionStatus}`)
      }

    }, [getPropertyFilter?.completionStatus])
    
  return (
    <>
      <PropertyListHead />
      <Tab.Container
        id="property-list-tabs"
        activeKey={activeKey}
        onSelect={handleTabNavigation}
        defaultActiveKey="All"
      >
        <div className="row mb-4">
          <div className="col-lg-6 d-lg-flex gap-2 align-items-center">
            <span>Completion Status:</span>
            <Nav variant="pills" className="property-list-tab-nav mt-2 mt-lg-0">
              <Nav.Item>
                <Nav.Link eventKey="All" onClick={resetFilterHandler}>
                  All <sup>{getPropertyFilterCounts}</sup>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Ready"
                  onClick={() => setFilterHandler("Ready")}
                >
                  Ready<sup>{getPropertyFilterCounts}</sup>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Off-Plan"
                  onClick={() => setFilterHandler("Off-Plan")}
                >
                  Off-Plan<sup>{getPropertyFilterCounts}</sup>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end align-items-center gap-3">
            <div className="input-icon-group left-gap">
              <i className="fa-solid fa-arrow-down-wide-short input-icon left"></i>
              <div className="select-wrapper">
                <select
                  name=""
                  id=""
                  className="form-control property-filter-input-md"
                >
                  <option hidden>Popular</option>
                </select>
              </div>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <button
                type="button"
                className={`property-list-view-icon ${
                  !layoutToggle ? "active" : ""
                }`}
                onClick={() => setLayoutToggle(!layoutToggle)}
              >
                <i className="bi bi-list-task" style={{ fontSize: "24px" }}></i>
              </button>
              <button
                type="button"
                className={`property-list-view-icon ${
                  layoutToggle ? "active" : ""
                }`}
                onClick={() => setLayoutToggle(!layoutToggle)}
              >
                <i className="bi bi-ui-checks-grid"></i>
              </button>
            </div>
          </div>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="All">
            <PropertyFilterListMain layoutToggle={layoutToggle} />
          </Tab.Pane>
          <Tab.Pane eventKey="Ready">
            <PropertyFilterListMain layoutToggle={layoutToggle} />
          </Tab.Pane>
          <Tab.Pane eventKey="Off-Plan">
            <PropertyFilterListMain layoutToggle={layoutToggle} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}
