import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import RoutesRoot from "./routes/RoutesRoot";

export default function App() {
  return (
    <>
      <RoutesRoot />
    </>
  );
}
