import React from "react";
import PropertyCardSmSkeleton from "../skeleton/PropertyCardSmSkeleton";

export default function PropertyCardSmSkeletonLoader({ limit }) {
  return (
    <>
      <div className="row gy-4">
        {Array.from({ length: limit }).map((_, index) => (
          <>
            <div className="col-lg-4">
              <PropertyCardSmSkeleton key={index} />
            </div>
          </>
        ))}
      </div>
    </>
  );
}
