import axios from "../config/NodeAxios";
import { handleApiError } from "../components/common/core/CommonFunctions";

export class KeywordService {
  constructor() {
    this.endpoints = {
      BY_NAME: "public/property-management/keyword/by_name/:keyName",
    };
    this.config = {};
    this.config.headers = {
      "content-type": "application/json",
    };
  }

  async getKeywordListByName(keyName) {
    try {
      const response = await axios.get(this.endpoints.BY_NAME.replace(":keyName", keyName));
      return response;
    } catch (error) {
      console.log("error", error);
      handleApiError(error);
    }
  }
}
