import React from "react";
import PropTypes from "prop-types";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { env } from "../../env";

const GoogleMapComponent = ({ lat, lng }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: env.place_api_key,
  });
  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  if (!lat && !lng) {
    return null;
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        height: "350px",
        width: "100%",
      }}
      center={{ lat, lng }}
      zoom={18}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
      }}
      onLoad={onMapLoad}
    >
      <MarkerF
        position={{ lat, lng }}
        draggable={true}
        icon={`${env?.imageBaseURL}/map-marker.png` || null}
        // onDragEnd={handleMarkerDragEnd}
      />
    </GoogleMap>
  );
};

export default GoogleMapComponent;
