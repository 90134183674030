import React from "react";
import { env } from "../env";

export default function AboutUs() {
  return (
    <>
      <main>
        <section className="about-us-intro">
          <div className="custom-container">
            <div className="row g-0">
              <div className="col-lg-5 about-us-img">
                <img
                  src={`${env.imageBaseURL}/about-us-group-1.jpg`}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-7 offset-lg-5 about-us-content">
                <h1 className="heading-2">About Us</h1>
                <p className="lg-text">
                  <b className="text-black">
                    Welcome to PropTy – Where Augmented Reality Meets Real
                    Estate!
                  </b>
                </p>
                <p>
                  At PropTy, we believe that finding your dream property should
                  be an immersive and exciting experience. That’s why we’ve
                  revolutionised the way you search for homes, offices, or
                  investment opportunities through our cutting-edge application
                  that integrates augmented reality (AR).
                </p>
                <p className="lg-text">
                  <b className="text-black">Our Mission</b>
                </p>
                <p>
                  Our mission at PropTy is to simplify the property search
                  process while providing users with an innovative and engaging
                  platform that harnesses the power of augmented reality.
                </p>
                <p className="lg-text">
                  <b className="text-black">Connect With Us</b>
                </p>
                <p>
                  Have questions or feedback? We’d love to hear from you! Get in
                  touch with the PropTy team via email, phone, or social media
                  to learn more about our services and how we can assist you in
                  achieving your real estate goals.
                </p>
                <p className="lg-text">
                  <b>
                    Thank you for choosing PropTy – Where Your
                    Property Journey Begins!
                  </b>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
