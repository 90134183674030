// Localhost
// export const env = {
//   adminURL: "http://propty.beyondd.co/backadmin",
//   serverURL: "http://localhost:3200",
//   place_api_key: "AIzaSyBvO2yEfy11ns6mfYf_c_7TNjacZPC-gf0",
//   imageBaseURL: "http://localhost:4001/images",
// };

// Development
// export const env = {
//   adminURL: "https://propty.beyondd.co/backadmin",
//   serverURL: "https://propty.beyondd.co:3200",
//   place_api_key: "AIzaSyBvO2yEfy11ns6mfYf_c_7TNjacZPC-gf0",
//   imageBaseURL: "https://propty.beyondd.co/images",
// };

// Live - use
export const env = {
  adminURL: "https://propty.co/backadmin",
  serverURL: "https://propty.beyondd.co:3200",
  place_api_key: "AIzaSyBvO2yEfy11ns6mfYf_c_7TNjacZPC-gf0",
  imageBaseURL: "https://propty.co/images",
};

// Live
// export const env = {
//   adminURL: "https://propty.co/backadmin",
//   serverURL: "https://propty.co:3300",
//   place_api_key: "AIzaSyBvO2yEfy11ns6mfYf_c_7TNjacZPC-gf0",
//   imageBaseURL: "https://propty.co/images",
// };
