import React from "react";
import { env } from "../../env";

export default function DownloadAppXrBox({ hideText = false }) {
  return (
    <>
      <div className="download-app-xr-box d-inline-block text-center">
        {!hideText && <b className="mb-2 d-block">COMING SOON!</b>}
        {/* {!hideText && <b className="mb-2 d-block">DOWNLOAD PROPTY APP NOW!</b>} */}
        <img
          src={`${env.imageBaseURL}/qrcode.png`}
          alt="QR Code"
          className="img-fluid"
        />
      </div>
    </>
  );
}
