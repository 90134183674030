import React from "react";
import { Accordion } from "react-bootstrap";
import DataNotFound from "../../data-not-found/DataNotFound";

export default function PageFaqBlock({ value }) {
  if (!value) {
    return null;
  }

  return (
    <>
      <section className="section-padding-md">
        <div className="custom-container">
          <div className="row">
            <div className="col-12 text-center mb-lg-4">
              {value?.faq_heading && (
                <h1 className="heading-2">{value?.faq_heading}</h1>
              )}
            </div>
            <div className="col-12">
              <Accordion className="faq-accordion" defaultActiveKey="0">
                {value?.faq_list && value?.faq_list.length > 0 ? (
                  value?.faq_list.map((eleFaqValue, eleFaqIndex) => (
                    <Accordion.Item eventKey={eleFaqIndex}>
                      <Accordion.Header>
                        {eleFaqIndex + 1}. {eleFaqValue.title}
                        <div className="accordion-icon">
                          <i className="fa-solid fa-chevron-down"></i>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>{eleFaqValue.content}</Accordion.Body>
                    </Accordion.Item>
                  ))
                ) : (
                  <DataNotFound msg="FAQs not found." />
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
