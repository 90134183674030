import React, { useState } from "react";
import { env } from "../../env";
import { NavLink, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import HeaderLanguageDropdown from "../common/header/HeaderLanguageDropdown";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  function headerMenuToggleHandler() {
    setMenuOpen(!menuOpen);
    closeDropdown();
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <>
      <div
        className={`overlay ${menuOpen ? "active" : ""}`}
        onClick={headerMenuToggleHandler}
      ></div>
      <header
        className={`main-header sticky-top ${menuOpen ? "menu-active" : ""}`}
      >
        <div className="custom-container header-grid">
          <div className="header-logo">
            <NavLink to="/">
              <img
                src={`${env.imageBaseURL}/propty-logo.png`}
                alt=""
                className="img-fluid"
              />
            </NavLink>
          </div>
          <div></div>
          {/* <div className="menubar d-lg-none" onClick={headerMenuToggleHandler}>
            <span></span>
            <span></span>
            <span></span>
          </div> */}
          {/* <div id="menu">
            <ul className="header-menu">
              <Dropdown show={dropdownOpen} onClick={toggleDropdown}>
                <Dropdown.Toggle
                  variant="success"
                  id="company"
                  className="nav-link"
                >
                  Company
                  <i className="fa-solid fa-chevron-down"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu onClick={headerMenuToggleHandler}>
                  <NavLink
                    to="/about-us"
                    className="dropdown-item"
                    onClick={closeDropdown}
                  >
                    About Us
                  </NavLink>
                  <NavLink
                    to="/careers"
                    className="dropdown-item"
                    onClick={closeDropdown}
                  >
                    Careers
                  </NavLink>
                  <NavLink
                    to="/media"
                    className="dropdown-item"
                    onClick={closeDropdown}
                  >
                    Media
                  </NavLink>
                  <NavLink
                    to="/contact-us"
                    className="dropdown-item"
                    onClick={closeDropdown}
                  >
                    Contact Us
                  </NavLink>
                </Dropdown.Menu>
              </Dropdown>
              <li onClick={headerMenuToggleHandler}>
                <NavLink to="/list-your-property" className="nav-link">
                  List Your Property
                </NavLink>
              </li>
              <li onClick={headerMenuToggleHandler}>
                <NavLink
                  to="/list-your-property/register/agent"
                  className="nav-link"
                >
                  Agent Hub
                </NavLink>
              </li>
              <li onClick={headerMenuToggleHandler}>
                <NavLink
                  to="#"
                  className="nav-link"
                  onClick={() => {
                    window.location.href = env?.adminURL;
                  }}
                >
                  Login
                </NavLink>
              </li>
            </ul>
            <div className="header-language d-lg-none">
              <HeaderLanguageDropdown />
            </div>
          </div> */}
          <div className="header-language d-flex">
            <HeaderLanguageDropdown />
          </div>
        </div>
      </header>
    </>
  );
}
