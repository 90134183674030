import React from "react";
import { Accordion } from "react-bootstrap";
import DataNotFound from "../../data-not-found/DataNotFound";

export default function PageFaqAndTabBlock({ value }) {
  if (!value) {
    return null;
  }

  return (
    <>
      <section className="section-padding-md">
        <div className="custom-container">
          <div className="row">
            <div className="col-12 text-center mb-4">
              <h1 className="heading-2">Frequently Asked Questions</h1>
            </div>
            {value?.faq_tabbed_list && value?.faq_tabbed_list.length > 0 ? (
              value.faq_tabbed_list.map((tabVal, tabIndex) => (
                <React.Fragment key={`tab_${tabIndex}`}>
                  <div className="col-12 text-center mb-lg-3">
                    {tabVal?.tab_title && (
                      <h2 className="heading-3">{tabVal.tab_title}</h2>
                    )}
                  </div>
                  <div className="col-12 mb-4 mb-lg-5">
                    <Accordion className="faq-accordion" defaultActiveKey="0">
                      {tabVal.tab_faq_list && tabVal.tab_faq_list.length > 0 ? (
                        tabVal.tab_faq_list.map((eleFaqValue, eleFaqIndex) => (
                          <Accordion.Item
                            eventKey={eleFaqIndex}
                            key={`faq_${eleFaqIndex}`}
                          >
                            <Accordion.Header>
                              {eleFaqIndex + 1}. {eleFaqValue.title}
                              <div className="accordion-icon">
                                <i className="fa-solid fa-chevron-down"></i>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: eleFaqValue.content,
                                }}
                              ></div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))
                      ) : (
                        <DataNotFound msg="FAQs not found." />
                      )}
                    </Accordion>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <DataNotFound msg="FAQs not found." />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
