import React, { useState } from "react";
import { useForm } from "react-hook-form";
import SocialMediaIcons from "../common/social-media/SocialMediaIcons";
import { FormService } from "../../services/FormService";
import { toast } from "react-toastify";

export default function SignMeUpForm() {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const [email, setEmail] = useState("");

  function formHandler(data) {
    const payload = {
      email: data.email,
    };

    new FormService()
      .SignMeUpSubmit(payload)
      .then((response) => {
        if (response.data) {
          toast.success(response.data.message, {});
          reset();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <div className="mx-auto mx-lg-0 t-right sign-me-up-form-box-wrapper">
        <form onSubmit={handleSubmit(formHandler)}>
          <div className="sign-me-up-form-box">
            <b className="mb-1">Count Me In!</b>
            <p className="mb-3">Get early access, news and updates</p>
            {errors.email && (
              <p className="input-error">{errors.email.message}</p>
            )}
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Enter your email here*"
              defaultValue={email}
              {...register("email", {
                required: "Email is required",
              })}
              aria-invalid={errors.email ? "true" : "false"}
            />
            <button type="submit" className="btn btn-primary mb-0">
              Sign Me Up!
            </button>
          </div>
        </form>
        <div className="d-flex justify-content-center mt-3">
          <SocialMediaIcons variant="blue" />
        </div>
      </div>
    </>
  );
}
