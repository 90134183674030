import axios from "../config/NodeAxios";
import { handleApiError } from "../components/common/core/CommonFunctions";

export class PropertyService {
  constructor() {
    this.endpoints = {
      PROPERTY_FILTER_LIST: "public/property-management/properties/list",
    };
    this.config = {};
    this.config.headers = {
      "content-type": "application/json",
    };
  }

  async getFilterPropertyList(pageQuery, payload) {
    try {
      let endpointsURL = this.endpoints.PROPERTY_FILTER_LIST;

      if (pageQuery) {
        endpointsURL += pageQuery;
      }

      const response = await axios.post(endpointsURL, payload, this.config);

      return response;
    } catch (error) {
      console.log("error", error);
      handleApiError(error);
    }
  }
}
