import React, { useEffect, useState } from "react";
import {
  getCityDropdownList,
  getCountryDropdownList,
  getKeywordDropdownByName,
  getLocationSearchDropdown,
} from "../../services/DropdownHelper";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  restePropertyFilter,
  setPropertyFilter,
} from "../../../redux-service/action/action";

export default function PropertyListFilters() {
  const [listingTypeDropdown, setListingTypeDropdown] = useState([]);
  const [propertyTypeDropdown, setPropertyTypeDropdown] = useState([]);
  const [bedroomDropdown, setBedroomDropdown] = useState([]);
  const [bathroomDropdown, setBathroomDropdown] = useState([]);
  const [searchdropdown, setSearchDropdown] = useState([]);
  const [searchdropdownValue, setSearchDropdownValue] = useState({
    value: "",
    label: "Search...",
  });
  const [countryDropdown, setCountryDropdown] = useState([]);

  const [countryId, setCountyId] = useState(null);
  const [cityDropdown, setCityDropdown] = useState([]);

  const [filter, setFilter] = useState({
    listing_type: "",
    search: "",
    property_type: "",
    beds: "",
    baths: "",
    country_name: "",
  });

  const [moreFilterToggle, setMoreFilterToggle] = useState(false);

  // const 
  const dispatch = useDispatch();

  const getPropertyFilterStatus = useSelector(
    (state) => state?.propertyFilterReducer?.isFiltering
  );

  const getPropertyFilter = useSelector(
    (state) => state?.propertyFilterReducer?.filterObject
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const listingTypeDropdown = await getKeywordDropdownByName("Purpose");
        setListingTypeDropdown(listingTypeDropdown);

        const propertyTypeDropdown = await getKeywordDropdownByName(
          "Property Type"
        );
        setPropertyTypeDropdown(propertyTypeDropdown);

        const bedroomDropdown = await getKeywordDropdownByName("Bedroom");
        if (bedroomDropdown && bedroomDropdown.length > 0) {
          setBedroomDropdown(bedroomDropdown);
        }

        const bathroomDropdown = await getKeywordDropdownByName("Bathroom");
        if (bathroomDropdown && bathroomDropdown.length > 0) {
          setBathroomDropdown(bathroomDropdown);
        }

        const searchdropdown = await getLocationSearchDropdown();
        setSearchDropdown(searchdropdown);

        const countryDropdown = await getCountryDropdownList();
        setCountryDropdown(countryDropdown);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        if (countryId) {
          const cityDropdown = await getCityDropdownList(countryId);
          setCityDropdown(cityDropdown);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [countryId]);

  function fetchFilterHandler(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }

  function fetchLocationFilterHandler(selectedOption) {
    setFilter({ ...filter, search: selectedOption?.label });
  }

  function fetchCountryHandler(e) {
    const { value } = e.target;

    setCountyId(value);
    const findCountryName = countryDropdown.find((ele) => ele.value === value);

    setFilter({
      ...filter,
      country_name: findCountryName?.label,
      city_name: "",
    });
  }

  function saveSearch() {
    const nonEmptyFilter = Object.fromEntries(
      Object.entries(filter).filter(([key, value]) => value !== "")
    );

    if (Object.keys(nonEmptyFilter).length > 0) {
      dispatch(setPropertyFilter({ ...getPropertyFilter, ...nonEmptyFilter }));
    } else {
      console.log("Filter object is empty. Not saving search.");
    }
  }

  function resetFilterHandler() {
    dispatch(restePropertyFilter());
    setFilter({
      listing_type: "",
      search: "",
      property_type: "",
      beds: "",
      baths: "",
      country_name: "",
      city_name: "",
    });
    setSearchDropdownValue({
      value: "",
      label: "Search...",
    })
    setCountyId("");
  }

  return (
    <>
      <div className="property-list-filter">
        <div className="property-filter-grid-1 mb-3">
          <div className="grid-item sm">
            <div className="select-wrapper">
              <select
                name="listing_type"
                className="form-control"
                value={filter?.listing_type}
                onChange={fetchFilterHandler}
              >
                {listingTypeDropdown &&
                  listingTypeDropdown.map((ele) => (
                    <option key={ele} value={ele}>
                      {ele}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="grid-item lg">
            <div className="input-icon-group right-gap">
              <i
                className="fa-solid fa-location-dot input-icon right"
                style={{ fontSize: "18px" }}
              ></i>
              <Select
                options={searchdropdown}
                value={searchdropdownValue}
                placeholder="Search..."
                className="mul-select-input"
                onChange={(selectedOption) => {
                  setSearchDropdownValue({
                    label: selectedOption?.label,
                    value: selectedOption?.label,
                  })
                  fetchLocationFilterHandler(selectedOption)
                }}
              />
            </div>
          </div>
          <div className="grid-item">
            <div className="select-wrapper">
              <select
                name="property_type"
                className="form-control"
                value={filter?.property_type}
                onChange={fetchFilterHandler}
              >
                <option hidden>Property Type</option>
                {propertyTypeDropdown &&
                  propertyTypeDropdown.map((ele) => (
                    <option key={ele} value={ele}>
                      {ele}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="grid-item">
            <div className="select-wrapper">
              <select
                name="beds"
                className="form-control"
                value={filter?.beds}
                onChange={fetchFilterHandler}
              >
                <option hidden>Beds</option>
                {bedroomDropdown &&
                  bedroomDropdown.map((ele) => (
                    <option key={ele} value={ele}>
                      {ele}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="grid-item">
            <div className="select-wrapper">
              <select
                name="baths"
                className="form-control"
                value={filter?.baths}
                onChange={fetchFilterHandler}
              >
                <option hidden>Baths</option>
                {bathroomDropdown &&
                  bathroomDropdown.map((ele) => (
                    <option key={ele} value={ele}>
                      {ele}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {moreFilterToggle && (
            <>
              <div className="grid-item">
                <div className="select-wrapper">
                  <select
                    name="country_name"
                    className="form-control"
                    value={countryId}
                    onChange={fetchCountryHandler}
                  >
                    <option hidden>Country</option>
                    {countryDropdown &&
                      countryDropdown.map((ele) => (
                        <option key={ele?.value} value={ele?.value}>
                          {ele?.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="grid-item">
                <div className="select-wrapper">
                  <select
                    name="city_name"
                    className="form-control"
                    value={filter?.city_name}
                    onChange={fetchFilterHandler}
                  >
                    <option hidden>City</option>
                    {cityDropdown &&
                      cityDropdown.map((ele) => (
                        <option key={ele?.value} value={ele?.label}>
                          {ele?.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </>
          )}
          <div className="grid-item filter-btn">
            <button
              className="btn btn-outline-dark btn-sm"
              onClick={() => {
                setMoreFilterToggle(!moreFilterToggle);
              }}
            >
              {!moreFilterToggle ? "More" : "Hide"} Filters{" "}
              <i className="bi bi-sliders2"></i>
            </button>
            {!getPropertyFilterStatus ? (
              <button
                type="button"
                className="btn btn-sky-blue-light btn-sm"
                onClick={saveSearch}
              >
                Save Search
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={resetFilterHandler}
              >
                Reset Search
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
