import React from "react";
import { env } from "../env";
import DownloadAppXrBox from "../components/download/DownloadAppXrBox";
import SignMeUpForm from "../components/forms/SignMeUpForm";

export default function Home() {
  return (
    <>
      <main
        className="home-layout overflow-hidden"
        style={{ backgroundImage: `url(${env.imageBaseURL}/home-bg.jpg)` }}
      >
        <div className="custom-container">
          <div className="home-wrapper">
            <section className="hero-banner-2 section-padding-md pt-0">
              <div>
                <div className="row flex-column-reverse flex-lg-row">
                  <div className="col-lg-5">
                    <div className="hero-banner-content-2 mb-lg-4 pb-2">
                      <h1 className="heading-4 text-uppercase text-blue">
                        Your all in one AR property search app
                      </h1>
                      <p className="mb-2 text-medium">
                        Be part of the future of real estate!
                      </p>
                      <p className="mb-2">
                        Join our waitlist today and receive exclusive benefits:
                      </p>
                      <ul>
                        <li>
                          <span className="text-medium">Early Access:</span> Be
                          among the first to experience the app when it
                          launches.
                        </li>
                        <li>
                          <span className="text-medium">Special Offers: </span>{" "}
                          Enjoy exclusive discounts and promotions upon launch.
                        </li>
                        <li>
                          <span className="text-medium">
                            Insider Information:
                          </span>{" "}
                          Get regular updates on the app’s development and
                          upcoming features.
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-end">
                      <SignMeUpForm />
                    </div>
                  </div>
                  <div className="col-lg-7 d-flex justify-content-center position-relative">
                    <img
                      src={`${env.imageBaseURL}/banner-mockup-2.png`}
                      alt="Home Banner Mockup"
                      className="img-fluid banner-mockup-2"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="why-propty-section section-padding-md pb-0">
              <div className="row gx-lg-5 gy-4 flex-lg-row-reverse align-items-center pb-2 mb-4 mb-lg-5">
                <div className="col-lg-4">
                  <div className="why-card-2">
                    <div className="card-image">
                      <img
                        src={`${env.imageBaseURL}/why-propty-1.png`}
                        className="img-fluid"
                        alt="Effortless Navigation:"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="why-card-2">
                    <div className="card-body">
                      <h3 className="heading-4">Effortless Navigation:</h3>
                      <p className="card-text">
                        Experience ease like never before with PropTy. Our
                        straightforward design makes it easy to find what you
                        need, fast. Perfect for everyone, no matter your tech
                        skill level.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gx-lg-5 gy-4 align-items-center pb-2 mb-4 mb-lg-5">
                <div className="col-lg-4">
                  <div className="why-card-2">
                    <div className="card-image">
                      <img
                        src={`${env.imageBaseURL}/why-propty-2.png`}
                        className="img-fluid"
                        alt="AR Technology Integration Through Your Camera Lens:"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="why-card-2">
                    <div className="card-body">
                      <h3 className="heading-4">
                        AR Technology Integration Through Your Camera Lens:
                      </h3>
                      <p className="card-text">
                        By integrating augmented reality (AR) technology into
                        our app, we’re revolutionizing the way you interact with
                        your surroundings. Through your device’s camera lens,
                        our app overlays digital information onto the real
                        world, providing an immersive and interactive experience
                        like never before. Whether you’re exploring a new city,
                        or simply trying to locate a nearby restaurant, our AR
                        feature enhances your perception of the world around
                        you, making tasks more engaging and informative.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gx-lg-5 gy-4 flex-lg-row-reverse align-items-center pb-2 pb-lg-0">
                <div className="col-lg-4">
                  <div className="why-card-2">
                    <div className="card-image">
                      <img
                        src={`${env.imageBaseURL}/why-propty-3.png`}
                        className="img-fluid"
                        alt="Real-Time Local Info:"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="why-card-2">
                    <div className="card-body">
                      <h3 className="heading-4">Real-Time Local Info:</h3>
                      <p className="card-text">
                        Stay updated wherever you are with PropTy. Our
                        GPS-driven data ensures you get the latest on places and
                        events nearby, always accurate and tailored just for
                        you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
}
