import React, { useEffect, useState } from "react";
import { env } from "../../env";
import DownloadAppXrBox from "../download/DownloadAppXrBox";
import { useDispatch, useSelector } from "react-redux";
import { AppDownloadPopupToggleAction } from "../../redux-service/action/action";

export default function DownloadProptyApp() {
  const [popUpToogle, setPopUpToogle] = useState(false);
  const dispatch = useDispatch();

  const getSuccessPopUpStatus = useSelector(
    (state) => state.RegisterReducer.downloadPopUpStatus
  );

  function closePopUp() {
    dispatch(AppDownloadPopupToggleAction(false));
  }

  useEffect(() => {
    if (getSuccessPopUpStatus !== undefined) {
      setPopUpToogle(getSuccessPopUpStatus);
    }
  }, [getSuccessPopUpStatus]);

  return (
    <>
      <div
        className={`form-success-popup form-download-app-popup ${
          popUpToogle ? "active" : ""
        }`}
      >
        <div className="form-success-popup-box">
          <div className="form-success-popup-box-head">
            <img
              src={`${env.imageBaseURL}/propty-white-logo.png`}
              alt="PropTy White Logo"
              className="img-fluid site-logo-md"
            />
            <p>SCAN THE QRCODE TO DOWNLOAD THE PROPTY APP NOW</p>
          </div>
          <div className="form-success-popup-box-body">
            <DownloadAppXrBox hideText={true} />
            <button
              type="button"
              className="btn btn-sky-blue"
              onClick={closePopUp}
            >
              Back
            </button>
          </div>
        </div>
        <div className="form-success-popup-overlay"></div>
      </div>
    </>
  );
}
