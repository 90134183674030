import React from "react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import PageScrollTop from "../../components/common/PageScrollTop";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import PageContentBlock from "../../components/pages/page-block/PageContentBlock";
import PagePageBanner from "../../components/pages/page-block/PagePageBanner";
import PageFaqBlock from "../../components/pages/page-block/PageFaqBlock";
import PageFaqAndTabBlock from "../../components/pages/page-block/PageFaqAndTabBlock";

export default function PageBlock({ value }) {
  if (!value) {
    return <Navigate to="/" />;
  }

  const orderedComponents = value.page_component_list.sort(
    (a, b) => a.page_block_order - b.page_block_order
  );

  return (
    <>
      <Helmet>
        <title>{`${value?.page_name} - PropTy`}</title>
      </Helmet>
      <PageScrollTop />
      <Header />
      <main>
        {orderedComponents.map((component) => {
          switch (component.page_block_id) {
            case "PAGE_BLOCK_CONTENT":
              return (
                <PageContentBlock
                  key={component.page_block_id}
                  value={component}
                />
              );
            case "PAGE_BLOCK_PAGE_BANNER":
              return (
                <PagePageBanner
                  key={component.page_block_id}
                  value={component}
                />
              );
            case "PAGE_BLOCK_FAQ":
              return (
                <PageFaqBlock key={component.page_block_id} value={component} />
              );
            case "PAGE_BLOCK_FAQ_AND_TAB":
              return (
                <PageFaqAndTabBlock
                  key={component.page_block_id}
                  value={component}
                />
              );
            default:
              return null;
          }
        })}
      </main>
      <Footer />
    </>
  );
}
