import React from "react";
import { NavLink } from "react-router-dom";
import SocialMediaIcons from "../common/social-media/SocialMediaIcons";

export default function Footer() {
  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center mb-lg-3 gap-lg-3">
              <ul className="footer-menu">
                <li>
                  <NavLink to="/about-us" className="nav-link">
                    About Us
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/careers" className="nav-link">
                    Careers
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/media" className="nav-link">
                    Media
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us" className="nav-link">
                    Contact Us
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/terms-and-conditions" className="nav-link">
                    Terms & Conditions
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/faq" className="nav-link">
                    FAQ
                  </NavLink>
                </li> */}
              </ul>
              <div className="my-4 my-lg-0">
                <SocialMediaIcons variant="white" />
              </div>
            </div>
            <div className="col-12 text-center">
              <p className="mb-0">
                Copyright 2024 © PropTy All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
