import React from "react";
import { env } from "../../../env";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";

export default function PropertyGallerySlider({ image, gallery, className }) {
  return (
    <>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className={className ? className : "property-card-gallery"}
      >
        {image ? (
          <>
            <SwiperSlide>
              <img
                src={`${env?.serverURL}${image}`}
                alt=""
                className="img-fluid"
                onError={(e) => {
                  e.target.src = `${env?.imageBaseURL}/property-default-img.jpg`;
                }}
              />
            </SwiperSlide>
            {gallery &&
              gallery?.length > 0 &&
              gallery?.map((galleryImg) => (
                <>
                  <SwiperSlide>
                    <img
                      src={`${env?.serverURL}${galleryImg}`}
                      alt=""
                      className="img-fluid"
                      onError={(e) => {
                        e.target.src = `${env?.imageBaseURL}/property-default-img.jpg`;
                      }}
                    />
                  </SwiperSlide>
                </>
              ))}
          </>
        ) : (
          <SwiperSlide>
            <img
              src={`${env?.imageBaseURL}/property-default-img.jpg`}
              alt="default property"
              className="img-fluid"
            />
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
}
