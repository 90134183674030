import React, { useState } from "react";
import { env } from "../env";
import PropertyListFilters from "../components/pages/property/PropertyListFilters";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import DownloadProptyApp from "../components/popup/DownloadProptyApp";
import PropertyListTab from "../components/pages/property/PropertyListTab";
import PropertyMapTab from "../components/pages/property/PropertyMapTab";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function PropertyList() {
  const [activeKey, setActiveKey] = useState("List");

  const handleTabNavigation = (tabText) => {
    setActiveKey(`${tabText}`);
  };

  return (
    <>
      <main
        className="property-list-page"
        style={{ backgroundImage: `url(${env.imageBaseURL}/property-bg.jpg)` }}
      >
        <div className="container property-wrapper-grid">
          <div className="col-12 text-center py-4 py-lg-5 px-2 px-lg-0">
            <h1 className="heading-2 mb-0">Where Comfort Meets Convenience</h1>
          </div>
          <div className="property-wrapper">
            <div className="property-wrapper-head">
              <img
                src={`${env.imageBaseURL}/propty-white-logo.png`}
                alt=""
                className="img-fluid site-logo-md"
              />
            </div>
            <div className="property-wrapper-body">
              <PropertyListFilters />
              <Tab.Container
                id="property-list-main-tab"
                activeKey={activeKey}
                onSelect={handleTabNavigation}
                defaultActiveKey="List"
              >
                <div className="row gy-4">
                  <div className="col-lg-6 d-flex flex-wrap gap-2 align-items-center">
                    <button className="btn-line btn-white">
                      <i class="fa-regular fa-map"></i>{" "}
                      <span>
                        TruCheck<sup>TM</sup> listings first
                      </span>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>TruCheck</Tooltip>}
                      >
                        <i class="bi bi-exclamation-circle"></i>
                      </OverlayTrigger>
                    </button>
                    <button className="btn-line btn-white">
                      <i class="fa-regular fa-map"></i> Properties with floor
                      plans
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>View floor plans</Tooltip>}
                      >
                        <i class="bi bi-exclamation-circle"></i>
                      </OverlayTrigger>
                    </button>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    <Nav variant="pills" className="property-list-tab-nav">
                      <Nav.Item>
                        <Nav.Link eventKey="List">List</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Map">Map</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <hr />
                <Tab.Content>
                  <Tab.Pane eventKey="List">
                    <PropertyListTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Map">
                    <PropertyMapTab />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </main>
      <DownloadProptyApp />
    </>
  );
}
