import axios from "../config/NodeAxios";
import { handleApiError } from "../components/common/core/CommonFunctions";

export class PageService {
  constructor() {
    this.endpoints = {
      LIST_PUBLIC: "public/page-management/pages/list",
    };
    this.config = {};
    this.config.headers = {
      "content-type": "application/json",
    };
  }

  async getPageList(query) {
    try {
      let response;

      if (query) {
        response = await axios.get(this.endpoints.LIST_PUBLIC + query);
      } else {
        response = await axios.get(this.endpoints.LIST_PUBLIC);
      }
      return response;
    } catch (error) {
      console.log("error", error);
      handleApiError(error);
    }
  }
}
