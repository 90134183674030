import { toast } from "react-toastify";

export const handleApiError = async (error) => {
  if (error.code === "ERR_NETWORK") {
    toast.error(
      "Network error occurred. Please check your internet connection.",
      {
        icon: <i className="fa-solid fa-triangle-exclamation"></i>,
      }
    );
  } else if (error.response && error.response.data) {
    const resp = error.response.data;
    if (resp.message) {
      toast.error(resp.message, {
        icon: <i className="fa-solid fa-triangle-exclamation"></i>,
      });
    } else {
      toast.error(resp, {
        icon: <i className="fa-solid fa-triangle-exclamation"></i>,
      });
    }
  }
};
