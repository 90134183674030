import { CityService } from "../../services/CityService";
import { CountryService } from "../../services/CountryService";
import { KeywordService } from "../../services/KeywordService";
import { LocationService } from "../../services/LocationService";

export async function getKeywordDropdownByName(keyName) {
  try {
    const result = await new KeywordService().getKeywordListByName(keyName);
    if (result.data) {
      return result?.data?.keyword_values;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getLocationSearchDropdown() {
  try {
    const result = await new LocationService().getLocationSearchDropdown();
    if (result.data && result?.data?.length > 0) {
      const newList = result?.data?.map((ele) => {
        return {
          label: ele.label,
          value: ele.gps1_location,
        };
      });

      return newList;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getCountryDropdownList() {
  try {
    const result = await new CountryService().getCountryDropdown();
    if (result.data) {
      return result?.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getCityDropdownList(countryId) {
  try {
    const result = await new CityService().getCityDropdownByID(countryId);
    if (result.data) {
      return result?.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}
