import React, { useEffect, useRef, useState } from "react";
import "../assets/css/property-single.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { env } from "../env";
import GoogleMapComponent from "../components/google-map/GoogleMapComponent";
import { NavLink, useParams } from "react-router-dom";
import { PropertyService } from "../services/PropertyService";
import PropertyGallerySlider from "../components/pages/property/PropertyGallerySlider";
import { Helmet } from "react-helmet";
import PageScrollTop from "../components/common/PageScrollTop";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { ToastContainer } from "react-toastify";

export default function PropertySinglePage() {
  const params = useParams();
  const { property_info } = params;

  const propertyId = property_info.split("__").pop();
  const [isLoader, setLoader] = useState(true);
  const [propertySingle, setPropertySingle] = useState();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const maxLength = 150;
  const description = propertySingle?.description || "";
  const truncatedDescription =
    description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;

  async function getPropertyFilterSingle() {
    try {
      if (propertyId) {
        setLoader(true);
        let filterObject = {
          id: propertyId,
        };
        const response = await new PropertyService().getFilterPropertyList(
          null,
          filterObject
        );
        setPropertySingle(response?.data?.result[0]);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error fetching property filter list:", error);
    }
  }

  useEffect(() => {
    getPropertyFilterSingle();
  }, [propertyId]);

  return (
    <>
      {/* {JSON.stringify(propertySingle, null, 1)} */}
      <Helmet>
        <title>
          {propertySingle?.web_meta?.title && propertySingle?.web_meta?.title
            ? propertySingle?.web_meta?.title
            : `${propertySingle?.name} - PropTy`}
        </title>
      </Helmet>
      <PageScrollTop />
      <Header />
      <main className="property-single-page">
        <div className="custom-container">
          <div className="row gx-xxl-5 gy-4">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-12">
                  <div className="property-single-swiper-wrapper">
                    {propertySingle?.completionStatus && (
                      <span className="completion-status">
                        {propertySingle?.completionStatus}
                      </span>
                    )}
                    <PropertyGallerySlider
                      image={propertySingle?.image}
                      gallery={propertySingle?.gallery}
                      className="property-single-swiper"
                    />
                  </div>
                </div>
                <div className="col-12 mt-4">
                  {propertySingle?.actual_price && (
                    <b className="title">
                      {propertySingle?.actual_price}{propertySingle?.currency?.currency && propertySingle?.currency?.currency}
                      {propertySingle?.pricePeriod &&
                        `/ ${propertySingle?.pricePeriod}`}
                    </b>
                  )}

                  <p className="mb-0">
                    <small>Bed Bath Unit Size</small>
                  </p>
                  <ul className="property-info-box ul-none">
                    <li>
                      <img
                        src={`${env.imageBaseURL}/bed-icon.png`}
                        alt=""
                        className="img-fluid"
                        width={32}
                        height={32}
                      />{" "}
                      {propertySingle?.bedrooms} Bedrooms
                    </li>
                    <li>
                      <img
                        src={`${env.imageBaseURL}/bath-icon.png`}
                        alt=""
                        className="img-fluid"
                        width={32}
                        height={32}
                      />{" "}
                      {propertySingle?.bathrooms} Bathrooms
                    </li>
                    <li>
                      <img
                        src={`${env.imageBaseURL}/unit-size-icon.png`}
                        alt=""
                        className="img-fluid"
                        width={32}
                        height={32}
                      />{" "}
                      {propertySingle?.total_unit_size.size}
                      {propertySingle?.total_unit_size.type}
                    </li>
                  </ul>
                </div>
                <div className="col-12">
                  <hr></hr>
                </div>
                <div className="col-12">
                  <b className="title">{propertySingle?.name}</b>
                  <p>
                    {isExpanded ? description : truncatedDescription}
                    {description.length > maxLength && (
                      <small
                        className="btn-expanded"
                        onClick={toggleExpanded}
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </small>
                    )}
                  </p>
                </div>
                <div className="col-12">
                  <hr></hr>
                </div>
                <div className="col-lg-6">
                  <b className="title">Propety information</b>
                  <div className="row gy-2 property-list-info">
                    <div className="col-6">Type</div>
                    <div className="col-6">
                      {propertySingle?.property_type || "N/A"}
                    </div>
                    <div className="col-6">Purpose</div>
                    <div className="col-6">
                      {propertySingle?.listing_type || "N/A"}
                    </div>
                    <div className="col-6">Refernce #</div>
                    <div className="col-6">
                      {propertySingle?.property_ref_number || "N/A"}
                    </div>
                    <div className="col-6">Furnishing</div>
                    <div className="col-6">
                      {propertySingle?.property_furnishing || "N/A"}
                    </div>
                    <div className="col-6">Added on</div>
                    <div className="col-6">
                      {propertySingle?.date_added_on || "N/A"}
                    </div>
                    <div className="col-6">Service Charge</div>
                    <div className="col-6">
                      {propertySingle?.service_charge?.charges
                        ? `${propertySingle?.service_charge?.charges}${propertySingle?.service_charge?.type}`
                        : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-12">
                  <b className="title">Amenities</b>
                  <div className="amenities-grid">
                    {propertySingle?.amenities &&
                      propertySingle.amenities.map((eleAme, index) => (
                        <div className="grid-item" key={index}>
                          {eleAme.icon ? (
                            <img
                              src={`${env.serverURL}${eleAme.icon}`}
                              alt={eleAme?.name || "Amenity Icon"}
                              className="img-fluid"
                              width={32}
                              height={32}
                              onError={(e) => {
                                e.target.src = `${env.imageBaseURL}/default-img-120.png`;
                              }}
                            />
                          ) : (
                            <img
                              src={`${env.imageBaseURL}/default-img-120.png`}
                              alt={eleAme?.name || "Amenity Icon"}
                              className="img-fluid"
                              width={32}
                              height={32}
                            />
                          )}
                          <b>{eleAme?.name}</b>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-12">
                  <b className="title">Location</b>
                  <address>
                    <i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;
                    {propertySingle?.address || "N/A"}
                  </address>
                  <GoogleMapComponent
                    lat={propertySingle?.location?.coordinates[1] || ""}
                    lng={propertySingle?.location?.coordinates[0] || ""}
                  />
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-lg-6">
                  <b className="title">Building information</b>
                  <div className="row gy-2 property-list-info">
                    <div className="col-6">Name</div>
                    <div className="col-6">{propertySingle?.property_name}</div>
                    <div className="col-6">Developer</div>
                    <div className="col-6">
                      {propertySingle?.developer.name}
                    </div>
                    <div className="col-6">Year of Completion</div>
                    <div className="col-6">
                      {propertySingle?.year_of_complete}
                    </div>
                    <div className="col-6">Floors</div>
                    <div className="col-6">{propertySingle?.floors}</div>
                    <div className="col-6">Plot Size</div>
                    <div className="col-6">
                      {propertySingle?.plot_size?.size
                        ? `${propertySingle?.plot_size?.size}${propertySingle?.plot_size?.type}`
                        : "N/A"}
                    </div>
                    <div className="col-6">Parking</div>
                    <div className="col-6">
                      {propertySingle?.parking_for_unit}
                    </div>
                    <div className="col-6">Ownership</div>
                    <div className="col-6">{propertySingle?.ownership}</div>
                    <div className="col-6">Balcony Size</div>
                    <div className="col-6">
                      {propertySingle?.total_balcony_size?.size
                        ? `${propertySingle?.total_balcony_size?.size}${propertySingle?.total_balcony_size?.type}`
                        : "N/A"}
                    </div>
                    <div className="col-6">Built-up Area</div>
                    <div className="col-6">
                      {propertySingle?.built_up_area?.size
                        ? `${propertySingle?.built_up_area?.size}${propertySingle?.built_up_area?.type}`
                        : "N/A"}
                    </div>
                    <div className="col-6">Parking Availability</div>
                    <div className="col-6">
                      {propertySingle?.parking_availability ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <hr></hr>
                </div>
                <div className="col-lg-6">
                  <b className="title">Regulatory Informtion</b>
                  <div className="row gy-2 property-list-info">
                    <div className="col-6">Trakheesi Permit</div>
                    <div className="col-6">
                      {propertySingle?.permit_number || "N/A"}
                    </div>
                    <div className="col-6">RARA</div>
                    <div className="col-6">{propertySingle?.rera || "N/A"}</div>
                    <div className="col-6">BRN</div>
                    <div className="col-6">
                      {propertySingle?.agent?.brn_no || "N/A"}
                    </div>
                    <div className="col-6">DED</div>
                    <div className="col-6">{propertySingle?.ded || "N/A"}</div>
                  </div>
                </div>
                <div className="col-12">
                  <hr></hr>
                </div>
                <div className="col-12">
                  <b className="title">Location & Nearby</b>
                  <div className="row gy-4">
                    {propertySingle?.location_and_nearby &&
                      propertySingle.location_and_nearby.map(
                        (eleLAN, index) => (
                          <div className="col-6 col-lg-3">
                            <div className="location-and-nearby-box">
                              <div className="location-and-nearby-box-head">
                                {eleLAN?.image ? (
                                  <img
                                    src={`${env?.serverURL}${eleLAN?.image}`}
                                    alt=""
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={`${env?.imageBaseURL}/property-default-img.jpg`}
                                    alt=""
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="location-and-nearby-box-body">
                                <b>{eleLAN?.name}</b>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
                <div className="col-12">
                  <hr></hr>
                </div>
                <div className="col-12 d-none d-lg-flex justify-content-end">
                  <NavLink to="/properties" className="btn btn-primary">
                    Back to properties
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="property-sidebar sticky-top">
                <div className="row gy-4">
                  <div className="col-12">
                    <div className="card agent-details">
                      <div className="card-image">
                        {propertySingle?.agent?.image ? (
                          <img
                            src={`${env.serverURL}${propertySingle?.agent?.image}`}
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={`${env.imageBaseURL}/user-default.jpg`}
                            alt=""
                            className="img-fluid"
                          />
                        )}{" "}
                      </div>
                      <div className="card-body">
                        <p className="text-center mb-2">
                          <b className="card-name">
                            {propertySingle?.agent?.name || "N/A"}
                          </b>
                        </p>
                        <ul className="property-contact ul-none mb-4">
                          <li className="contact-btn">
                            <NavLink to={`to:${propertySingle?.agent?.call}`}>
                              <i
                                className="fas fa-phone"
                                style={{ fontSize: "12px" }}
                              ></i>{" "}
                              Call
                            </NavLink>
                          </li>
                          <li className="contact-btn">
                            <NavLink
                              to={`mailto:${propertySingle?.agent?.email}`}
                            >
                              <i
                                className="fas fa-envelope"
                                style={{ fontSize: "15px" }}
                              ></i>{" "}
                              Email
                            </NavLink>
                          </li>
                          <li className="contact-btn whatsapp">
                            <a
                              href={`https://wa.me/${propertySingle?.agent?.whatsApp}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="fab fa-whatsapp"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </a>
                          </li>
                        </ul>
                        <div className="card-bottom">
                          <b>{propertySingle?.agent?.agency?.name || "N/A"}</b>
                          <div className="d-flex justify-content-end">
                            {propertySingle?.agent?.agency?.agencyLogo ? (
                              <img
                                src={`${env.serverURL}${propertySingle?.agent?.agency?.agencyLogo}`}
                                alt=""
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={`${env.imageBaseURL}/property-default-img.jpg`}
                                alt=""
                                className="img-fluid"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card agent-details">
                      <div className="card-image">
                        {propertySingle?.trakheesi_permit ? (
                          <img
                            src={`${env.serverURL}${propertySingle?.trakheesi_permit}`}
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={`${env.imageBaseURL}/property-default-img.jpg`}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <div className="card-body">
                        <p className="text-center mb-0">
                          <b className="card-name">Trakheesi Permit</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-end d-lg-none">
              <NavLink to="/properties" className="btn btn-primary">
                Back to properties
              </NavLink>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <ToastContainer />
    </>
  );
}
