import React from "react";
import { env } from "../../../env";

export default function PagePageBanner({ value }) {
  if (!value) {
    return null;
  }

  return (
    <>
      <section className="inner-page-banner">
        {value?.page_banner_img && (
          <img
            src={`${env.serverURL}/uploads/website-pages/${value?.page_banner_img}`}
            alt="FAQ Banner"
            className="img-fluid"
          />
        )}
      </section>
    </>
  );
}
