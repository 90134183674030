import React from "react";
import { Dropdown } from "react-bootstrap";
import { env } from "../../../env";

export default function HeaderLanguageDropdown() {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="success"
          id="language-dropdown-toggle"
          className="nav-link"
        >
          <img
            src={`${env.imageBaseURL}/uk_flag2.png`}
            alt="Language"
            className="img-fluid flag"
          />
          EN
          <i className="fa-solid fa-chevron-down"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#">English</Dropdown.Item>
          <Dropdown.Item href="#">Arabic</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
