import axios from "../config/NodeAxios";
import { handleApiError } from "../components/common/core/CommonFunctions";

export class FormService {
  constructor() {
    this.endpoints = {
      CONTACT_US: "form-management/contact-us",
      SIGN_ME_UP: "form-management/sign-me-up",
    };
    this.config = {};
    this.config.headers = {
      "content-type": "application/json",
    };
  }

  async contactUsFormSubmit(payload) {
    try {
      const response = await axios.post(
        this.endpoints.CONTACT_US,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      console.log("error", error);
      handleApiError(error);
    }
  }

  async SignMeUpSubmit(payload) {
    try {
      const response = await axios.post(
        this.endpoints.SIGN_ME_UP,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      console.log("error", error);
      handleApiError(error);
    }
  }
}
