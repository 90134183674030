import React from "react";
import { env } from "../env";
import { useForm } from "react-hook-form";
import { FormService } from "../services/FormService";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

export default function ContactUs() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function formHandler(data) {
    const payload = {
      name: data.name,
      email: data.email,
      mobile: data.telephone,
      message: data.message,
      companyName: data.companyName,
    };

    new FormService()
      .contactUsFormSubmit(payload)
      .then((response) => {
        if (response.data) {
          toast.success(response.data.message, {
            icon: <i className="fa-solid fa-check"></i>,
          });
          reset();
        }
      })
      .catch((error) => {
        // console.error(JsonData.messages.errors.update + error);
      });
  }

  return (
    <>
      <main
        className="contact-page"
        style={{
          backgroundImage: `url(${env?.imageBaseURL}/contact-us-bg.jpg)`,
        }}
      >
        <section>
          <div className="custom-container">
            <div className="contact-bg-box-blue" id="contact-us-form">
              <div className="row">
                <div className="col-lg-4 mb-4 mb-lg-0">
                  <div className="d-flex justify-content-center mb-4">
                    <img
                      src={`${env.imageBaseURL}/propty-white-logo.png`}
                      alt="PropTy White Logo"
                      className="img-fluid site-logo-md"
                    />
                  </div>
                  <h2 className="heading-4 text-white mb-3">Contact Us</h2>
                  <ul className="contact-info-list ul-none">
                    <li>
                      <i class="fa-solid fa-envelope"></i>
                      <NavLink to="mailto:info@propty.co">
                        info@propty.co
                      </NavLink>
                    </li>
                    <li>
                      <i class="fa-solid fa-mobile"></i>
                      <NavLink to="to:+97149876543">+971 4 987 6543</NavLink>
                    </li>
                    <li>
                      <i class="fa-brands fa-whatsapp"></i>
                      <NavLink to="https://wa.me/+971501234567">
                        +971 50 123 4567
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-8">
                  <form
                    onSubmit={handleSubmit(formHandler)}
                    className="contact-bg-box-white"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="error-group">
                          <label for="name">Full name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter Your Name"
                            {...register("name", {
                              required: "Name is required",
                            })}
                            aria-invalid={errors.name ? "true" : "false"}
                          />
                          {errors.name && (
                            <p className="input-error-feedback">
                              {errors.name.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="error-group">
                          <label for="companyName">Company name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            placeholder="Enter your company name"
                            {...register("companyName", {
                              required: "Company name is required",
                            })}
                            aria-invalid={errors.name ? "true" : "false"}
                          />
                          {errors.name && (
                            <p className="input-error-feedback">
                              {errors.name.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="error-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Enter Your Email Address"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Invalid email address",
                              },
                            })}
                            aria-invalid={errors.email ? "true" : "false"}
                          />
                          {errors.email && (
                            <p className="input-error-feedback">
                              {errors.email.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="error-group">
                          <label htmlFor="telephone">Phone number</label>
                          <div className="flag-group">
                            <img
                              src={`${env.imageBaseURL}/uae-flag.jpg`}
                              alt="UAE Flag"
                              className="img-fluid"
                            />
                            <input
                              type="text"
                              className="form-control"
                              id="telephone"
                              name="telephone"
                              placeholder="+971"
                              {...register("telephone", {
                                pattern: {
                                  value: /^\+971\s\d*$/,
                                  message:
                                    "Please enter a valid telephone number in the format +971 000",
                                },
                              })}
                              aria-invalid={errors.telephone ? "true" : "false"}
                            />
                          </div>
                          {errors.telephone && (
                            <p className="input-error-feedback">
                              {errors.telephone.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="error-group">
                          <label for="message">Message</label>
                          <textarea
                            rows={4}
                            className="form-control"
                            id="message"
                            placeholder="Enter Your Message"
                            {...register("message", {
                              required: "Message is required",
                            })}
                            aria-invalid={errors.message ? "true" : "false"}
                          ></textarea>
                          {errors.message && (
                            <p className="input-error-feedback">
                              {errors.message.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn btn-primary">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
