import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageScrollTop from "../common/PageScrollTop";

export default function DefaultView({ cmp, head }) {
  const Component = cmp;

  return (
    <>
      <Helmet>
        <title>{head && head.title ? head.title : "PropTy"}</title>
      </Helmet>
      <PageScrollTop />
      <Header />
      <Component />
      <Footer />
      <ToastContainer />
    </>
  );
}
