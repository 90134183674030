import {
  APP_DOWNLOAD_POPUP_TOGGLE,
  PROPERTY_FILTER_COUNTS,
  PROPERTY_FILTER_RESET,
  PROPERTY_FILTER_SET,
  REGISTRATION_SUCCESS_POPUP_TOGGLE,
} from "../constants";

const registerInitialState = {
  successPopUpStatus: false,
  downloadPopUpStatus: false,
};

export const RegisterReducer = (state = registerInitialState, action) => {
  switch (action.type) {
    case REGISTRATION_SUCCESS_POPUP_TOGGLE:
      return {
        ...state,
        successPopUpStatus: action.data,
      };
    case APP_DOWNLOAD_POPUP_TOGGLE:
      return {
        ...state,
        downloadPopUpStatus: action.data,
      };
    default:
      return state;
  }
};

const initialState = {
  isFiltering: false,
  filterObject: null,
  filterCounts: 0,
};

export const propertyFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTY_FILTER_SET:
      const baseUrl = window.location.href.split("?")[0];
      const params = new URLSearchParams(window.location.search);

      Object.entries(action.data).forEach(([key, value]) => {
        params.set(key, value);
      });

      const newUrl = `${baseUrl}?${params.toString()}`;
      window.history.replaceState(null, "", newUrl);

      return {
        ...state,
        isFiltering: true,
        filterObject: action.data,
      };
    case PROPERTY_FILTER_RESET:
      const baseUrlReset = window.location.href.split("?")[0];
      window.history.replaceState(null, "", baseUrlReset);
      
      return {
        ...state,
        isFiltering: false,
        filterObject: null,
      };
    case PROPERTY_FILTER_COUNTS:
      return {
        ...state,
        filterCounts: action?.data,
      };
    default:
      return state;
  }
};
