import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function PropertyCardSmSkeleton() {
  return (
    <>
      <div className="property-card-sm">
        <div className="property-card-image">
          <Skeleton />
        </div>
        <div className="property-card-content">
          <div className="property-info">
            <h3 className="card-price mb-2">
              <Skeleton height={30} />
            </h3>
            <Skeleton count={2} />
          </div>
          <ul className="property-contact ul-none mt-3">
            <li className="contact-btn">
              <Skeleton height={30} />
            </li>
            <li className="contact-btn">
              <Skeleton height={30} />
            </li>
            <li className="contact-btn whatsapp">
              <Skeleton height={30} />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
