import React from "react";

export default function PageContentBlock({ value }) {
  if (!value) {
    return null;
  }

  return (
    <>
      <section className="section-padding-md terms-page">
        <div className="custom-container">
          <div className="row">
            {value?.page_heading && (
              <div className="col-12 mb-3 mb-lg-4">
                <h2 className="heading-2 text-center">{value?.page_heading}</h2>
              </div>
            )}
            {value?.page_content && (
              <div
                className="col-12"
                dangerouslySetInnerHTML={{ __html: value?.page_content }}
              ></div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
