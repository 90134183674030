import React from "react";
import { env } from "../env";
import SocialMediaIcons from "../components/common/social-media/SocialMediaIcons";

export default function Media() {
  return (
    <>
      <main>
        <section
          className="media-section section-padding pt-0"
          style={{ backgroundImage: `url(${env.imageBaseURL}/media-bg.jpg)` }}
        >
          <img
            src={`${env.imageBaseURL}/media-mockup.png`}
            alt=""
            className="img-fluid media-mockup"
          />
          <div className="media-section-bg-white section-padding">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-lg-7">
                  <h1 className="heading-2">
                    Your PropTy all in one media hub
                  </h1>

                  <p>
                    We love nothing more than making life easy. <br />
                    Get the latest news and media updates on how PropTy is
                    doing, right here.
                  </p>
                  <p>
                    For any media enquiries, please reach out to
                    <br />
                    <a href="mailto:media@propty.co">media@propty.co</a>
                    <br />
                    You can download our press kit below:
                  </p>
                  <div className="media-contact-card mt-4">
                    <img
                      src={`${env.imageBaseURL}/propty-white-logo.png`}
                      alt="PropTy White Logo"
                      className="img-fluid site-logo-md mb-3"
                    />
                    <p className="text">
                      <b>FOLLOW US ON SOCIAL MEDIA FOR MORE</b>
                    </p>
                    <div className="d-flex justify-content-center">
                      <SocialMediaIcons variant="white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
