import React, { useEffect, useState } from "react";
import PropertyCardLg from "../../cards/PropertyCardLg";
import PropertyCardSm from "../../cards/PropertyCardSm";
import { PropertyService } from "../../../services/PropertyService";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import PropertyCardSkeletonLoader from "../../loader/PropertyCardSkeletonLoader";
import DataNotFound from "../../data-not-found/DataNotFound";
import PropertyCardSmSkeletonLoader from "../../loader/PropertyCardSmSkeletonLoader";
import { setPropertyFilterCounts } from "../../../redux-service/action/action";
import { useSearchParams } from "react-router-dom";

export default function PropertyFilterListMain({ layoutToggle }) {
  const [propertyList, setPropertyList] = useState([]);
  const [isLoader, setLoader] = useState(true);

  // pagination
  // eslint-disable-next-line no-unused-vars
  const [totalCounts, setTotalCounts] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get("page");

  useEffect(() => {
    if (currentPage) {
      setPage(Number(currentPage));
    } else {
      setPage(1);
    }
  }, []);

  const dispatch = useDispatch();

  function pagiHandler(e, page) {
    searchParams.set("page", page);
    setSearchParams(searchParams);
    setPage(page);
  }

  const getPropertyFilterStatus = useSelector(
    (state) => state?.propertyFilterReducer?.isFiltering
  );
  const getPropertyFilter = useSelector(
    (state) => state?.propertyFilterReducer?.filterObject
  );

  async function getPropertyFilterListMain() {
    try {
      let pageQuery = `?page=${page}&limit=${limit}`;

      setLoader(true);
      let filterObject = getPropertyFilterStatus ? getPropertyFilter : {};
      const response = await new PropertyService().getFilterPropertyList(
        pageQuery,
        filterObject
      );
      setPropertyList(response?.data?.result);
      setTotalCounts(response?.data?.paginationInfo?.totalCount);
      setTotalPages(response?.data?.paginationInfo?.totalPages);
      if (page > response?.data.paginationInfo.totalPages) {
        setPage(1);
      }
      setLoader(false);
      dispatch(
        setPropertyFilterCounts(response?.data?.paginationInfo?.totalCount)
      );
    } catch (error) {
      console.error("Error fetching property filter list:", error);
    }
  }

  useEffect(() => {
    getPropertyFilterListMain();
  }, [page, limit, getPropertyFilter, getPropertyFilterStatus]);

  useEffect(() => {
    if (layoutToggle) {
      setLimit(6);
    } else {
      setLimit(5);
    }
  }, [layoutToggle]);

  return (
    <>
      <div className="row gy-4">
        {!isLoader ? (
          propertyList && propertyList.length > 0 ? (
            <>
              {propertyList.map((property) => (
                <>
                  {layoutToggle ? (
                    <div className="col-lg-4" key={property.id}>
                      <PropertyCardSm property={property} />
                    </div>
                  ) : (
                    <div className="col-12" key={property.id}>
                      <PropertyCardLg property={property} />
                    </div>
                  )}
                </>
              ))}
              <div className="col-12 d-flex justify-content-end">
                <Pagination
                  color="primary"
                  defaultPage={page}
                  count={totalPages}
                  size="large"
                  onChange={pagiHandler}
                />
              </div>
            </>
          ) : (
            <>
              <DataNotFound msg="No properties found." />
            </>
          )
        ) : (
          <div className="col-12">
            {layoutToggle ? (
              <PropertyCardSmSkeletonLoader limit={limit} />
            ) : (
              <PropertyCardSkeletonLoader limit={limit} />
            )}
          </div>
        )}
      </div>
    </>
  );
}
