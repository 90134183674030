import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function PropertyListHead() {
  const getPropertyFilterStatus = useSelector(
    (state) => state?.propertyFilterReducer?.isFiltering
  );

  const getPropertyFilter = useSelector(
    (state) => state?.propertyFilterReducer?.filterObject
  );

  // if (!getPropertyFilterStatus) {
  //   return null;
  // }

  return (
    <>
      <div className="property-list-header mb-5">
        <div className="breadcrumb mb-4">
          <span>For {getPropertyFilter?.listing_type || "Any Type"}:</span>
          <ul className="ul-none">
            <li>
              <NavLink to="#">
                {getPropertyFilter?.country_name || "Any Country"}
              </NavLink>
            </li>
            <li>
              <i className="fa-solid fa-angle-right"></i>
            </li>
            <li>
              <NavLink to="#">
                {getPropertyFilter?.city_name || "Any City"}
              </NavLink>
            </li>
            <li>
              <i className="fa-solid fa-angle-right"></i>
            </li>
            <li>{getPropertyFilter?.search || "Any Location"}</li>
          </ul>
        </div>
        <h2 className="heading-3">
          {getPropertyFilter?.property_type} for{" "}
          {getPropertyFilter?.listing_type} in{" "}
          {getPropertyFilter?.search || "Any Location"}
        </h2>
      </div>
    </>
  );
}
