import React from "react";

export default function DataNotFound({ msg }) {
  return (
    <>
      <div className="col-12">
        <div className="no-property-data">{msg ? msg : "No data found."}</div>
      </div>
    </>
  );
}
